<script lang="ts" setup>
import Card from "@/Components/Card.vue";

defineProps<{
    type: "success" | "error" | "warning" | "info";
    title?: string;
}>();

const colors = {
    success: "border-emerald-600",
    error: "border-red-600",
    warning: "border-orange-500",
    info: "border-sky-500",
};
</script>

<template>
    <Card
        :class="colors[type]"
        class="mb-6 mt-4 flex flex-auto flex-col rounded border-l-4 p-3 lg:mx-0"
    >
        <div class="flex flex-col">
            <div class="font-medium uppercase">
                <span v-if="title">{{ title }}</span>
                <span v-else>{{ type }}</span>
            </div>
            <div class="mt-1 text-sm">
                <slot />
            </div>
        </div>
    </Card>
</template>